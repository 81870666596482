* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6rem;
}

p,
h2,
label,
input,
button,
textarea {
  font-family: "Montserrat", sans-serif;
}
